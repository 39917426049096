import React, { useEffect, useRef, useState } from 'react';
import { Link } from 'react-router-dom';
import 'datatables.net-dt/css/dataTables.dataTables.css';
import $ from 'jquery'; 
import 'datatables.net'; 
import swal from 'sweetalert';
import {ServerUrl} from '../../../../../api/ServerUrl';
import { ProtectAPi } from '../../../../../api/ProtectAPi';
import { SetPermission } from '../../../../../auth/SetPermission';
import EditIcon from '../../../../tools/EditIcon';
import DeleteIcon from '../../../../tools/DeleteIcon';

const PublicationList = () => {

    
  const { http } = ProtectAPi();
  const httpCPage = ServerUrl;
  const tableRef = useRef(null);
  const [tableData, setTableData] = useState([]);
  const userPermission = SetPermission();

  useEffect(() => {
    http.get('/get-publication-list')
      .then(res => { 
        setTableData(res.data);
      })
      .catch(error => {
        console.error('Error fetching users:', error);
      });
  }, []);

  
  useEffect(() => {
      if (tableData.length > 0 && !tableRef.current.DataTable) {
          $(tableRef.current).DataTable();
      } else {
          if ($.fn.DataTable.isDataTable('#table-1')) {
              $(tableRef.current).DataTable().destroy();
          }
      }
  }, [tableData]);


  // delete roles
  const handleDeleteRole = (roleId) => {
      swal({
          title: 'Are you sure?',
          text: 'Once deleted, you will not be able to recover this item!',
          icon: 'warning',
          buttons: true,
          dangerMode: true,
      }).then((willDelete) => {
          if (willDelete) {
              http.delete(`/delete-publication/${roleId}`)
                 .then(response => {
                     if(response.data.status !== 201){
                      setTableData(prevRoles => prevRoles.filter(data => data.id !== roleId));
                     }
                      
                      swal(response.data.msg, {
                          icon: response.data.alert,
                      });
                 })
                 .catch(error => {
                      swal('Oops! Something went wrong while deleting this item.', {
                          icon: 'error',
                      });
                 });
              swal('Poof! This item has been deleted!', {
                  icon: 'success',
              });
          } else {
              swal('This item is safe!');
          }
      });
  };


  return (
    <div>
        <div className="main-content">
          <section className="section">
            <div className="section-body">
              <div className="row">
                <div className="col-12">
                  <div className="card">
                    <div className="card-body">
                        <div class="d-flex justify-content-between mb-3">
                            <h5>Publication List</h5>
                            <div class="text-end">
                                <Link to='/admin/pages/home/publication-add' className="btn btn-primary btn-sm">Add New</Link>
                            </div>
                        </div>
                      <div className="table-responsive">
                        <table className="table table-striped" id="table-1" ref={tableRef}>
                          <thead>
                            <tr>
                              <th className="text-center">#</th>
                              <th>Title</th>
                              <th>Image</th>
                              <th>Action</th>
                            </tr>
                          </thead>
                          <tbody>
                            {tableData.map((data, index) => (
                              <tr key={data.id}>
                                <td>{index + 1}</td>
                                <td>{data.title}</td>
                                <td><img alt="" src={httpCPage + '/backend/img/publications/' + data.image} width="60" /></td>
                                <td>
                                {userPermission.includes('home_all_edit') && (<Link to={`/admin/pages/home/publication-edit/${data.id}`} className="editbtn mr-1">
                                      <button className="btn btn-warning">
                                        <EditIcon/>
                                      </button>
                                    </Link>
                                  )}
                                  {userPermission.includes('home_delete') && (<Link to='' className="editbtn">
                                        <button className="btn btn-danger" onClick={() => handleDeleteRole(data.id)}>
                                            <DeleteIcon/>
                                        </button>
                                    </Link>
                                    )}
                                </td>
                              </tr>
                            ))}
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>
    </div>
  )
}


export default PublicationList