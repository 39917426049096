import React, { useEffect, useRef, useState } from 'react';
import { Link } from 'react-router-dom';
import 'datatables.net-dt/css/dataTables.dataTables.css';
import $ from 'jquery';
import 'datatables.net';
import swal from 'sweetalert';
import { SetPermission } from '../../../../../auth/SetPermission';
import { ProtectAPi } from '../../../../../api/ProtectAPi';
import EditIcon from '../../../../tools/EditIcon';
import DeleteIcon from '../../../../tools/DeleteIcon';

const TermsShow = () => {
    const userPermission = SetPermission();
    const { http } = ProtectAPi();
    const tableRef = useRef(null);
    const [termslist, setTerms] = useState([]);
  
    useEffect(() => {
        http.get('/setting-get-terms')
            .then(res => { 
                setTerms(res.data);
            })
            .catch(error => {
                console.error('Error fetching users:', error);
            });
    }, []);
  
    useEffect(() => {
        if (termslist.length > 0 && !tableRef.current.DataTable) {
            $(tableRef.current).DataTable();
        }
    }, [termslist]);

    // delete category
    const handleDeleteCategory = (deleteID) => {
        swal({
            title: 'Are you sure?',
            text: 'Once deleted, you will not be able to recover this terms!',
            icon: 'warning',
            buttons: true,
            dangerMode: true,
        }).then((willDelete) => {
            if (willDelete) {
                http.delete(`/delete-single-terms/${deleteID}`)
                    .then(response => {
                        if (response.data.status !== 201) {
                            setTerms(prevCategories => prevCategories.filter(category => category.id !== deleteID));
                        }
    
                        swal(response.data.msg, {
                            icon: response.data.alert,
                        });
                    })
                    .catch(error => {
                        swal('Oops! Something went wrong while deleting the terms.', {
                            icon: 'error',
                        });
                    });
            } else {
                swal('The terms is safe!');
            }
        });
    };

  return (
    <div>
        <div className="main-content">
            <section className="section">
                <div className="section-body">
                    <div className="row">
                        <div className="col-12">
                            <div className="card card-primary">
                                <div className="card-body">
                                    <div className="d-flex">
                                        <div className="mb-2">
                                            <Link to="/admin/settings" className="btn border btn-sm mr-2">Web Info</Link>
                                        </div>
                                        <div className="mb-2">
                                            <Link to="/admin/settings/banner-list" className="btn border btn-sm mr-2">Banner</Link>
                                        </div>
                                        <div className="mb-2">
                                            <Link to="/admin/settings/footer-list" className="btn border btn-sm mr-2">Footer</Link>
                                        </div>
                                        <div className="mb-2">
                                            <Link to="/admin/settings/terms-list" className="btn btn-primary btn-sm mr-2">Terms & Conditions</Link>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="card">
                                <div className="card-body">
                                    <div className="d-flex justify-content-between mb-3">
                                        <h5>Terms List</h5>
                                        <div className="text-end">
                                            <Link to='/admin/settings/terms-add' className="btn btn-primary btn-sm">Add New Terms</Link>
                                        </div>
                                    </div>
                                    <div className="table-responsive">
                                        <table className="table table-striped" id="table-1" ref={tableRef}>
                                            <thead>
                                                <tr>
                                                    <th className="text-center">#</th>
                                                    <th>Terms Name</th>
                                                    <th>Action</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {termslist.map((termslist, index) => (
                                                    <tr key={index}>
                                                        <td>{index + 1}</td>
                                                        <td>{termslist.termsName}</td> {/* Display category name */}
                                                        <td>
                                                            {userPermission.includes('edit_setting') && (<Link to={`/admin/settings/terms-edit/${termslist.id}`} className="editbtn mr-1">
                                                                    <button className="btn btn-warning">
                                                                        <EditIcon/>
                                                                    </button>
                                                                </Link>
                                                                )}
                                                                {userPermission.includes('edit_setting') && (<Link to='' className="editbtn">
                                                                    <button className="btn btn-danger" onClick={() => handleDeleteCategory(termslist.id)}>
                                                                        <DeleteIcon/>
                                                                    </button>
                                                                </Link>
                                                                )}
                                                        </td>
                                                    </tr>
                                                ))}
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    </div>
  )
}

export default TermsShow
