import React, { useState } from 'react';
import axios from 'axios'

export const ProtectAPi = () => {
    const token = localStorage.getItem('token');
    const http = axios.create({
        // baseURL: 'https://footservertest.ipsitacomputersltd.com/api',
        baseURL: 'https://footbalancetech.com/footserver/api',
        // baseURL: 'http://127.0.0.1:8000/api',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': token ? `Bearer ${token}` : null
        }
      });
      return {
        http
      }
}
