import React, { useEffect, useRef, useState } from 'react';
import { Link } from 'react-router-dom';
import 'datatables.net-dt/css/dataTables.dataTables.css';
import $ from 'jquery'; 
import 'datatables.net'; 
import swal from 'sweetalert';
import { SetPermission } from '../../../../auth/SetPermission';
import { ProtectAPi } from '../../../../api/ProtectAPi';

const AppoinmentConfirmed = () => {

      
  const { http } = ProtectAPi();
  const tableRef = useRef(null);
  const [tableData, setTableData] = useState([]);
  const userPermission = SetPermission();

  useEffect(() => {
    http.get('/get-pages-appoinment-confirmed')
      .then(res => { 
        setTableData(res.data);
      })
      .catch(error => {
        console.error('Error fetching users:', error);
      });
  }, []);

  
  useEffect(() => {
      if (tableData.length > 0 && !tableRef.current.DataTable) {
          $(tableRef.current).DataTable();
      } else {
          if ($.fn.DataTable.isDataTable('#table-1')) {
              $(tableRef.current).DataTable().destroy();
          }
      }
  }, [tableData]);


  function formatDate(dateString) {
    const options = { day: 'numeric', month: 'long', year: 'numeric' };
    return new Date(dateString).toLocaleDateString('en-US', options);
  }

  return (
    <div>
        <div className="main-content">
          <section className="section">
            <div className="section-body">
              <div className="row">
                <div className="col-12">
                  <div className="card card-primary">
                    <div className="card-body">
                      <div className="d-flex">
                        <div className="mb-2">
                          <Link to="/admin/pages/appoinment-list" className="btn border btn-sm mr-2">Pending List</Link>
                        </div>
                        <div className="mb-2">
                          <Link to="/admin/pages/appoinment-list-confirmed" className="btn btn-primary btn-sm mr-2">Appoinment Confirmed</Link>
                        </div>
                        <div className="mb-2">
                          <Link to="/admin/pages/appoinment-list-today" className="btn border btn-sm mr-2">Appoinment Today</Link>
                        </div>
                        <div className="mb-2">
                          <Link to="/admin/pages/appoinment-scheduled-confirm" className="btn border btn-sm mr-2">Scheduled Confirm</Link>
                        </div>
                        <div className="mb-2">
                          <Link to="/admin/pages/sms-sent-quantity" className="btn border btn-sm mr-2">Sms Package</Link>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="card">
                    <div className="card-body">
                        <div class="d-flex justify-content-between mb-3">
                            <h5>Appoinment Confirm list</h5>
                            {/* <div class="text-end">
                                <Link to='/admin/pages/research-development-add' className="btn btn-primary btn-sm">Add New Research</Link>
                            </div> */}
                        </div>
                      <div className="table-responsive">
                        <table className="table table-striped" id="table-1" ref={tableRef}>
                          <thead>
                            <tr>
                              <th className="text-center">#</th>
                              <th>Name</th>
                              <th>Location</th>
                              <th>Number</th>
                              <th>Request Date</th>
                              <th>status</th>
                            </tr>
                          </thead>
                          <tbody>
                            {tableData.map((data, index) => (
                              <tr key={data.id}>
                                <td>{index + 1}</td>
                                <td>{data.name}</td>
                                <td>{data.location}</td>
                                <td>{data.number}</td>
                                <td>{formatDate(data.created_at)}</td>
                                <td>
                                    {data.status === 1 && <span className='badge badge-primary'>Date Confirmed</span> }
                                    {data.status === 0 && <span className='badge badge-danger'>Not Confirmed</span> }
                                </td>
                              </tr>
                            ))}
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>
    </div>
  )
}

export default AppoinmentConfirmed