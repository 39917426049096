import React, { useEffect, useRef, useState } from 'react';
import { Link } from 'react-router-dom';
import 'datatables.net-dt/css/dataTables.dataTables.css';
import $ from 'jquery'; 
import 'datatables.net'; 
import { ProtectAPi } from '../../../../api/ProtectAPi';
import swal from 'sweetalert';
import { SetPermission } from '../../../../auth/SetPermission';
import EditIcon from '../../../tools/EditIcon';
import DeleteIcon from '../../../tools/DeleteIcon';

const ViewPermission = () => {

    const { http } = ProtectAPi();
    const tableRef = useRef(null);
    const [permissions, setPermissions] = useState([]);
    const userPermission = SetPermission();
  
    useEffect(() => {
      http.get('/get-all-permission')
        .then(res => { 
            setPermissions(res.data);
        })
        .catch(error => {
          console.error('Error fetching users:', error);
        });
    }, []);
  
    useEffect(() => {
      if (permissions.length > 0 && !tableRef.current.DataTable) {
        $(tableRef.current).DataTable();
      }
    }, [permissions]);


     // delete permission
     const handleDeleteRole = (permissionId) => {
        swal({
            title: 'Are you sure?',
            text: 'Once deleted, you will not be able to recover this Permission!, It can be harmful to your system',
            icon: 'warning',
            buttons: true,
            dangerMode: true,
        }).then((willDelete) => {
            if (willDelete) {
                http.delete(`/delete-permissions/${permissionId}`)
                   .then(response => {
                       if(response.data.status != 201){
                        setPermissions(prevRoles => prevRoles.filter(permission => permission.id !== permissionId));
                       }
                        
                        swal(response.data.msg, {
                            icon: response.data.alert,
                        });
                   })
                   .catch(error => {
                        swal('Oops! Something went wrong while deleting the Permission.', {
                            icon: 'error',
                        });
                   });
                swal('Poof! The Permission has been deleted!', {
                    icon: 'success',
                });
            } else {
                swal('The Permission is safe!');
            }
        });
        };


        


  return (
    <div>
        <div className="main-content">
          <section className="section">
            <div className="section-body">
              <div className="row">
                <div className="col-12">
                  <div className="card">
                    <div className="card-body">
                        <div class="d-flex justify-content-between mb-3">
                            <h5>Permission List</h5>
                            <div class="text-end">
                              {userPermission.includes('permission_create') && (<Link to="/admin/permissions-create" class="btn btn-sm btn-primary"><i class="fas fa-plus"></i> Create Permission</Link>)}
                            </div>
                        </div>
                      <div className="table-responsive">
                        <table className="table table-striped" id="table-1" ref={tableRef}>
                          <thead>
                            <tr>
                              <th className="text-center">#</th>
                              <th>Group Name</th>
                              <th>Permission Name</th>
                              <th>Action</th>
                            </tr>
                          </thead>
                          <tbody>
                            {permissions.map((permissions_group, index) => (
                              <tr key={permissions_group.id}>
                                <td>{index + 1}</td>
                                <td>{permissions_group.group_name}</td>
                                <td>{permissions_group.name}</td>
                                <td>
                                  {userPermission.includes('permission_edit') && (<Link to={`/admin/permissions-edit/${permissions_group.id}`} className="editbtn mr-1">
                                    <button className="btn btn-warning">
                                      <EditIcon/>
                                    </button>
                                  </Link>
                                  )}
                                  {userPermission.includes('permission_delete') && (<Link to='' className="editbtn">
                                        <button className="btn btn-danger" onClick={() => handleDeleteRole(permissions_group.id)}>
                                            <DeleteIcon/>
                                        </button>
                                    </Link>)}
                                </td>
                              </tr>
                            ))}
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>
      </div>
  )
}

export default ViewPermission