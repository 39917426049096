import React, { useEffect } from 'react'
import { Outlet } from 'react-router-dom'
import Header from './layouts/Header'
import Footer from './layouts/Footer'
import ScrollUp from './tools/ScrollUp'
import ChatBot from './tools/ChatBot'

const HomeLayout = () => {
  useEffect(() => {
    // Add CSS files
    const addStylesheet = (href) => {
      const link = document.createElement('link')
      link.rel = 'stylesheet'
      link.href = href
      document.head.appendChild(link)
    }

    // Add JS files
    const addScript = (src) => {
      const script = document.createElement('script')
      script.src = src
      script.async = true
      document.body.appendChild(script)
    }

    addStylesheet(`${process.env.PUBLIC_URL}/assets/css/bootstrap.min.css`)
    addStylesheet(`${process.env.PUBLIC_URL}/assets/css/style.css`)
    addStylesheet(`${process.env.PUBLIC_URL}/assets/css/custom.css`)
    addStylesheet(`${process.env.PUBLIC_URL}/assets/css/responsive.css`)

    addScript(`${process.env.PUBLIC_URL}/assets/js/bootstrap.min.js`)

   
  }, [])

  return (
    <div>
      <Header />
      <Outlet />
      <ScrollUp />
      <ChatBot />
      <Footer />
    </div>
  )
}

export default HomeLayout
