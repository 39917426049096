import React, { useEffect, useState  } from 'react';
import { BrowserRouter as Router, Routes, Route, useLocation } from 'react-router-dom';

// Front end
import Home from './components/Home';
import HomeLayoutDescription from './components/description/HomeLayoutDescription';
import AboutUs from './components/pages/about/AboutUs';
import MissionVission from './components/pages/about/MissionVission';
import AboutDescription from './components/pages/about/AboutDescription';
import Service from './components/pages/services/Service';
import ServicesDetails from './components/pages/services/ServicesDetails';
import Case from './components/pages/case_study/Case';
import OurFactory from './components/pages/our_factory/OurFactory';
import RdDetails from './components/pages/our_factory/RdDetails';
import Testimoniul from './components/pages/testimoniul/Testimoniul';
import Fhih from './components/pages/fhih/Fhih';
import FhihDetails from './components/pages/fhih/FhihDetails';
import Contact from './components/pages/contactUs/Contact';
import ProductListF from './components/pages/product/ProductList';
import ProductDetails from './components/pages/product/ProductDetails';
import Appoentment from './components/pages/appoentment/Appoentment';
import BlogListF from './components/pages/blog/BlogList';
import BlogDetails from './components/pages/blog/BlogDetails';
import GlobalSearch from './components/pages/search/GlobalSearch';
import HomeLayout from './components/HomeLayout';
import TermsCondition from './components/pages/termsCondition/TermsCondition';
import AwardAll from './components/pages/award/AwardAll';
import PublicationListF from './components/pages/publications/PublicationList';
import PublicationDetails from './components/pages/publications/PublicationDetails';

// Backend
import UserList from './components/backend/pages/user/UserList';
import UserCreate from './components/backend/pages/user/UserCreate';
import UserEdite from './components/backend/pages/user/UserEdite';
import ViewRole from './components/backend/pages/authentication/Role/ViewRole';
import CreateRole from './components/backend/pages/authentication/Role/CreateRole';
import EditRole from './components/backend/pages/authentication/Role/EditRole';
import ViewPermission from './components/backend/pages/authentication/permission/ViewPermission';
import CreatePermission from './components/backend/pages/authentication/permission/CreatePermission';
import EditPermission from './components/backend/pages/authentication/permission/EditPermission';
import UserProfile from './components/backend/pages/user/UserProfile';
import { SetPermission } from './components/auth/SetPermission';
import ShowUsers from './components/backend/pages/user/ShowUser';
import WebInfo from './components/backend/pages/settings/webInfo/WebInfo';
import Banner from './components/backend/pages/settings/webInfo/banner/Banner';
import BannerList from './components/backend/pages/settings/webInfo/banner/BannerList';
import EditBanner from './components/backend/pages/settings/webInfo/banner/EditBanner';
import FooterCategoryList from './components/backend/pages/settings/webInfo/footer/FooterCategoryList';
import FooterCategory from './components/backend/pages/settings/webInfo/footer/FooterCategory';
import FooterCategoryEdit from './components/backend/pages/settings/webInfo/footer/FooterCategoryEdit';
import TermsShow from './components/backend/pages/settings/webInfo/terms/TermsShow';
import TermsAdd from './components/backend/pages/settings/webInfo/terms/TermsAdd';
import TermsEdit from './components/backend/pages/settings/webInfo/terms/TermsEdit';
import LayoutView from './components/backend/pages/menu/home/home_layouts/LayoutView';
import LayoutAdd from './components/backend/pages/menu/home/home_layouts/LayoutAdd';
import LayoutEdit from './components/backend/pages/menu/home/home_layouts/LayoutEdit';
import AwardList from './components/backend/pages/menu/home/home_award/AwardList';
import AwardAdd from './components/backend/pages/menu/home/home_award/AwardAdd';
import AwardEdit from './components/backend/pages/menu/home/home_award/AwardEdit';
import VideoAdd from './components/backend/pages/menu/home/home_video/VideoAdd';
import TestimoniulList from './components/backend/pages/menu/home/testimoniul/TestimoniulList';
import TestimoniulAdd from './components/backend/pages/menu/home/testimoniul/TestimoniulAdd';
import TestimoniulEdit from './components/backend/pages/menu/home/testimoniul/TestimoniulEdit';
import ProcessList from './components/backend/pages/menu/home/work_process/ProcessList';
import ProcessEdit from './components/backend/pages/menu/home/work_process/ProcessEdit';
import BlogList from './components/backend/pages/menu/home/blog/BlogList';
import BlogAdd from './components/backend/pages/menu/home/blog/BlogAdd';
import BlogEdit from './components/backend/pages/menu/home/blog/BlogEdit';
import AboutLayoutList from './components/backend/pages/menu/about_us/layouts/AboutLayoutList';
import AboutLayoutAdd from './components/backend/pages/menu/about_us/layouts/AboutLayoutAdd';
import AboutLayoutEdit from './components/backend/pages/menu/about_us/layouts/AboutLayoutEdit';
import AddUpdateMv from './components/backend/pages/menu/about_us/missionVission/AddUpdateMv';
import GroupImage from './components/backend/pages/menu/about_us/layouts/GroupImage';
import ServicesList from './components/backend/pages/menu/services/ServicesList';
import ServicesAdd from './components/backend/pages/menu/services/ServicesAdd';
import ServicesEdit from './components/backend/pages/menu/services/ServicesEdit';
import CaseList from './components/backend/pages/menu/case_study/CaseList';
import CaseAdd from './components/backend/pages/menu/case_study/CaseAdd';
import CaseEdit from './components/backend/pages/menu/case_study/CaseEdit';
import FactoryInfo from './components/backend/pages/menu/our_factory/factory_info/FactoryInfo';
import RdList from './components/backend/pages/menu/our_factory/rd/RdList';
import RdAdd from './components/backend/pages/menu/our_factory/rd/RdAdd';
import RdEdit from './components/backend/pages/menu/our_factory/rd/RdEdit';
import FhihList from './components/backend/pages/menu/fhih/FhihList';
import FhihAdd from './components/backend/pages/menu/fhih/FhihAdd';
import FhihEdit from './components/backend/pages/menu/fhih/FhihEdit';
import ContactList from './components/backend/pages/menu/contact_us/ContactList';
import ContactView from './components/backend/pages/menu/contact_us/ContactView';
import AppoinmentList from './components/backend/pages/menu/appoinment/AppoinmentList';
import AppoinmentConfirmed from './components/backend/pages/menu/appoinment/AppoinmentConfirmed';
import AppoinmentToday from './components/backend/pages/menu/appoinment/AppoinmentToday';
import ScheduledConfirm from './components/backend/pages/menu/appoinment/ScheduledConfirm';
import ProductCategory from './components/backend/pages/product/category/ProductCategory';
import ProductCategoryAdd from './components/backend/pages/product/category/ProductCategoryAdd';
import ProductCategoryEdit from './components/backend/pages/product/category/ProductCategoryEdit';
import ProductAdd from './components/backend/pages/product/product/ProductAdd';
import ProductEdit from './components/backend/pages/product/product/ProductEdit';
import ProductList from './components/backend/pages/product/product/ProductList';
import FaqList from './components/backend/pages/faq/FaqList';
import FaqAdd from './components/backend/pages/faq/FaqAdd';
import FaqEdit from './components/backend/pages/faq/FaqEdit';
import BotResponseList from './components/backend/pages/bot/BotResponseList';
import BotResponseAdd from './components/backend/pages/bot/BotResponseAdd';
import BotResponseEdit from './components/backend/pages/bot/BotResponseEdit';
import NoteList from './components/backend/pages/menu/note/NoteList';
import NoteAdd from './components/backend/pages/menu/note/NoteAdd';
import NoteEdit from './components/backend/pages/menu/note/NoteEdit';
import ReviewList from './components/backend/pages/product/product/ReviewList';
import BackImageList from './components/backend/pages/menu/back_image/BackImageList';
import BackImageAdd from './components/backend/pages/menu/back_image/BackImageAdd';
import BackImageEdit from './components/backend/pages/menu/back_image/BackImageEdit';
import FactoryInfoList from './components/backend/pages/menu/our_factory/factory_info/FactoryInfoList';
import FactoryInfoAdd from './components/backend/pages/menu/our_factory/factory_info/FactoryInfoAdd';
import ProductPagesList from './components/backend/pages/menu/our_factory/product_pages/ProductPagesList';
import ProductPagesAdd from './components/backend/pages/menu/our_factory/product_pages/ProductPagesAdd';
import ProductPagesEdit from './components/backend/pages/menu/our_factory/product_pages/ProductPagesEdit';
import PublicationList from './components/backend/pages/menu/about_us/publications/PublicationList';
import PubliationAdd from './components/backend/pages/menu/about_us/publications/PubliationAdd';
import PublicationEdit from './components/backend/pages/menu/about_us/publications/PublicationEdit';
import AdminLayout from './components/AdminLayout';
import AuthLayout from './components/AuthLayout';
import AdminHome from './components/AdminHome';
import SmsPackage from './components/backend/pages/menu/appoinment/SmsPackage';


export default function App() {
  return (
    <Router>
      <MyApp />
    </Router>
  );
}
function MyApp() {
  
  const location = useLocation();
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const userPermission = SetPermission();

  useEffect(() => {
    window.scroll({
      top: 0,
      behavior: "smooth",
    });
  },[location.pathname]);

  

  useEffect(() => {
    const token = localStorage.getItem('token');
    if (token) {
      setIsAuthenticated(true);
    }
  }, []);

  useEffect(() => {
    const token = localStorage.getItem('token');
    const currentPath2 = window.location.pathname;
    if (currentPath2 === '/login') {
      if(token){
        window.location.href = '/admin';
      }
    }
    if(currentPath2 === '/admin' && !token){
      window.location.href = '/login';
    }
  }, []);

  return (
      <Routes>

        <Route path="/" element={<HomeLayout />}>
            <Route index element={<Home />} />
            <Route path="/layout/details/:id" element={<HomeLayoutDescription />} />
            {/* About Us */}
            <Route path="about-us" element={<AboutUs />} />
            <Route path="mission-vission" element={<MissionVission />} />
            <Route path="about/details/:id" element={<AboutDescription />} />
            {/* Services */}
            <Route path="/our-services" element={<Service />} />
            <Route path="/our-services/details/:id" element={<ServicesDetails />} />
            {/* Case Study */}
              <Route path="/case-study" element={<Case />} />
              <Route path="/case-study/:id" element={<Case />} />
            {/* Our Factory */}
              <Route path="/our-factory" element={<OurFactory />} />
              <Route path="/our-factory/research-development-details/:id" element={<RdDetails />} />
            {/* Testimonial */}
            <Route path="/testimonial" element={<Testimoniul />} />
            {/* Fhih */}
              <Route path="/foot-health-information-hub" element={<Fhih />} />
              <Route path="/foot-health-information-hub-details/:id" element={<FhihDetails />} />
            {/* Contact Us */}
              <Route path="/contact-us" element={<Contact />} />
            {/* Contact Us */}
            {/* Product */}
              <Route path="/product-list" element={<ProductListF/>} />
              <Route path="/product-details/:id" element={<ProductDetails/>} />

            {/* Product */}
            {/* Appoentment */}
              <Route path="/book-appointment" element={<Appoentment/>} />
            {/* Appoentment */}
            {/* Blog */}
              <Route path="/blog-list" element={<BlogListF/>} />
              <Route path="/blog-list-details/:id" element={<BlogDetails/>} />
            {/* Blog */}

             {/* Publication */}
             <Route path="/publication-list" element={<PublicationListF/>} />
              <Route path="/publication-details/:id" element={<PublicationDetails/>} />
            {/* Publication */}

            {/* Search */}
              <Route path="/search" element={<GlobalSearch/>} />
            {/* Search */}
            {/* terms */}
              <Route path="/terms-and-conditions" element={<TermsCondition/>} />
              <Route path="/terms-and-conditions/:id" element={<TermsCondition/>} />
            {/* terms */}
            {/* Award */}
              <Route path="/award-list" element={<AwardAll/>} />
            {/* Award */}
        </Route>

        <Route path="/login" element={<AuthLayout/>} />

        {isAuthenticated && (
          <>
            
              <Route path="/admin" element={<AdminLayout />}>
                <Route index element={<AdminHome />} />
                {/* User Route */}
                  {userPermission.includes('user_view') && (<Route path="users-list" element={<UserList />} />)}
                  {userPermission.includes('user_view') && (<Route path="users-show/:id" element={<ShowUsers/>} />)}
                  {userPermission.includes('user_edit') && (<Route path="users-edit/:id" element={<UserEdite />} />)}
                  {userPermission.includes('user_create') && (<Route path="users-create" element={<UserCreate />} />)}
                  <Route path="users-profile" element={<UserProfile/>} />
                {/* User Route */}
                
                {/* Role Route */}
                  {userPermission.includes('role_view') && (<Route path="roles" element={<ViewRole />} />)}
                  {userPermission.includes('role_create') && (<Route path="roles-create" element={<CreateRole />} />)}
                  {userPermission.includes('role_edit') && (<Route path="roles-edit/:id" element={<EditRole />} />)}
                {/* Role Route */}

                {/* Permission Route */}
                  {userPermission.includes('permission_view') && (<Route path="permissions" element={<ViewPermission />} />)}
                  {userPermission.includes('permission_create') && (<Route path="permissions-create" element={<CreatePermission />} />)}
                  {userPermission.includes('permission_edit') && (<Route path="permissions-edit/:id" element={<EditPermission />} />)}
                {/* Permission Route */}

                {/* Settings Route */}
                  {userPermission.includes('view_setting') && (<Route path="settings" element={<WebInfo/>} />)}
                    {/* Banner */}
                      {userPermission.includes('view_setting') && (<Route path="settings/banner" element={<Banner/>} />)}
                      {userPermission.includes('view_setting') && (<Route path="settings/banner-list" element={<BannerList/>} />)}
                      {userPermission.includes('edit_setting') && (<Route path="setting/banner-edit/:id" element={<EditBanner/>} />)}
                    {/* Banner */}

                    {/* Footer */}
                      {userPermission.includes('view_setting') && (<Route path="settings/footer-list" element={<FooterCategoryList/>} />)}
                      {userPermission.includes('view_setting') && (<Route path="settings/footer-category-add" element={<FooterCategory/>} />)}
                      {userPermission.includes('edit_setting') && (<Route path="settings/footer-category-edit/:id" element={<FooterCategoryEdit/>} />)}
                    {/* Footer */}

                    {/* Terms  a*/}
                      {userPermission.includes('view_setting') && (<Route path="settings/terms-list" element={<TermsShow/>} />)}
                      {userPermission.includes('view_setting') && (<Route path="settings/terms-add" element={<TermsAdd/>} />)}
                      {userPermission.includes('edit_setting') && (<Route path="settings/terms-edit/:id" element={<TermsEdit/>} />)}
                    {/* Terms */}
                {/* Settings Route */}

                {/* Pages Routes */}
                  {userPermission.includes('home_all_view') && (<Route path="pages/home/layouts" element={<LayoutView/>} />)}
                  {userPermission.includes('home_all_add') && (<Route path="pages/home/layouts-add" element={<LayoutAdd/>} />)}
                  {userPermission.includes('home_all_edit') && (<Route path="pages/home/layouts-edit/:id" element={<LayoutEdit/>} />)}

                    {/* Award */}
                      {userPermission.includes('home_all_view') && (<Route path="pages/home/award-list" element={<AwardList/>} />)}
                      {userPermission.includes('home_all_add') && (<Route path="pages/home/award-add" element={<AwardAdd/>} />)}
                      {userPermission.includes('home_all_edit') && (<Route path="pages/home/award-edit/:id" element={<AwardEdit/>} />)}
                    {/* Award */}
                    {/* Video Ad */}
                      {userPermission.includes('home_all_view') && (<Route path="pages/home/video-ad" element={<VideoAdd/>} />)}
                    {/* Video Ad */}
                    {/* Testimoniul */}
                      {userPermission.includes('home_all_view') && (<Route path="pages/home/testimoniul-list" element={<TestimoniulList/>} />)}
                      {userPermission.includes('home_all_add') && (<Route path="pages/home/testimoniul-add" element={<TestimoniulAdd/>} />)}
                      {userPermission.includes('home_all_edit') && (<Route path="pages/home/testimoniul-edit/:id" element={<TestimoniulEdit/>} />)}
                    {/* Testimoniul */}
                    {/* Work Process */}
                      {userPermission.includes('home_all_view') && (<Route path="pages/home/work-process-list" element={<ProcessList/>} />)}
                      {userPermission.includes('home_all_edit') && (<Route path="pages/home/work-process-edit/:id" element={<ProcessEdit/>} />)}
                    {/* Work Process */}

                    {/* Blog */}
                      {userPermission.includes('home_all_view') && (<Route path="pages/home/blog-list" element={<BlogList/>} />)}
                      {userPermission.includes('home_all_add') && (<Route path="pages/home/blog-add" element={<BlogAdd/>} />)}
                      {userPermission.includes('home_all_edit') && (<Route path="pages/home/blog-edit/:id" element={<BlogEdit/>} />)}
                    {/* Blog */}

                    {/* About Layouts */}
                      {userPermission.includes('about_all_view') && (<Route path="pages/about/layouts" element={<AboutLayoutList/>} />)}
                      {userPermission.includes('about_all_add') && (<Route path="pages/about/layouts-add" element={<AboutLayoutAdd/>} />)}
                      {userPermission.includes('about_all_add') && (<Route path="pages/about/layouts-group-image" element={<GroupImage/>} />)}
                      {userPermission.includes('about_all_edit') && (<Route path="admin/pages/about/layouts-edit/:id" element={<AboutLayoutEdit/>} />)}
                    {/* About Layouts */}
                    {/* About Mission Vission */}
                      {userPermission.includes('about_all_add') && (<Route path="pages/about/mission-vission" element={<AddUpdateMv/>} />)}
                    {/* About Mission Vission */}

                    {/* Publications */}
                      {userPermission.includes('about_all_view') && (<Route path="pages/home/publication-list" element={<PublicationList/>} />)}
                      {userPermission.includes('about_all_add') && (<Route path="pages/home/publication-add" element={<PubliationAdd/>} />)}
                      {userPermission.includes('about_all_add') && (<Route path="pages/home/publication-edit/:id" element={<PublicationEdit/>} />)}
                    {/* Publications */}

                    {/* Services Route */}
                      {userPermission.includes('services_view') && (<Route path="pages/home/services-list" element={<ServicesList/>} />)}
                      {userPermission.includes('services_add') && (<Route path="pages/home/services-add" element={<ServicesAdd/>} />)}
                      {userPermission.includes('services_edit') && (<Route path="pages/home/services-edit/:id" element={<ServicesEdit/>} />)}
                    {/* Services Route */}

                    {/* Case Study Route */}
                      {userPermission.includes('caseStudy_view') && (<Route path="pages/case-study-list" element={<CaseList/>} />)}
                      {userPermission.includes('caseStudy_add') && (<Route path="pages/case-study-add" element={<CaseAdd/>} />)}
                      {userPermission.includes('caseStudy_edit') && (<Route path="pages/case-study-edit/:id" element={<CaseEdit/>} />)}
                    {/* Case Study Route */}

                    {/* Product Route */}
                      {userPermission.includes('caseStudy_view') && (<Route path="pages/product-pages-list" element={<ProductPagesList/>} />)}
                      {userPermission.includes('caseStudy_add') && (<Route path="pages/product-pages-add" element={<ProductPagesAdd/>} />)}
                      {userPermission.includes('caseStudy_edit') && (<Route path="pages/product-pages-edit/:id" element={<ProductPagesEdit/>} />)}
                    {/* Case Study Route */}

                    {/* Our Factory */}
                      {userPermission.includes('factory_edit') && (<Route path="pages/our-factory-info-add" element={<FactoryInfoAdd/>} />)}
                      {userPermission.includes('factory_edit') && (<Route path="pages/our-factory-info-list" element={<FactoryInfoList/>} />)}
                      {userPermission.includes('factory_edit') && (<Route path="pages/our-factory-info/:id" element={<FactoryInfo/>} />)}
                      {userPermission.includes('research_development_view') && (<Route path="pages/research-development" element={<RdList/>} />)}
                      {userPermission.includes('research_development_add') && (<Route path="pages/research-development-add" element={<RdAdd/>} />)}
                      {userPermission.includes('research_development_edit') && (<Route path="pages/research-development-edit/:id" element={<RdEdit/>} />)}
                    {/* Our Factory */}

                    {/* Fhih */}
                      {userPermission.includes('fhih_view') && (<Route path="pages/fhih" element={<FhihList/>} />)}
                      {userPermission.includes('fhih_add') && (<Route path="pages/fhih-add" element={<FhihAdd/>} />)}
                      {userPermission.includes('fhih_edit') && (<Route path="pages/fhih-edit/:id" element={<FhihEdit/>} />)}
                    {/* Fhih */}
                    {/* Contact Us */}
                      {userPermission.includes('view_message') && (<Route path="pages/contact-us" element={<ContactList/>} />)}
                      {userPermission.includes('view_message') && (<Route path="pages/contact-list-view/:id" element={<ContactView/>} />)}
                    {/* Contact Us */}

                    {/* Appoinment */}
                      {userPermission.includes('confirm_date') && (<Route path="pages/appoinment-list" element={<AppoinmentList/>} />)}
                      {userPermission.includes('confirm_date') && (<Route path="pages/appoinment-list-confirmed" element={<AppoinmentConfirmed/>} />)}
                      {userPermission.includes('confirm_date') && (<Route path="pages/appoinment-list-today" element={<AppoinmentToday/>} />)}
                      {userPermission.includes('confirm_date') && (<Route path="pages/appoinment-scheduled-confirm" element={<ScheduledConfirm/>} />)}
                      {userPermission.includes('confirm_date') && (<Route path="pages/sms-sent-quantity" element={<SmsPackage/>} />)}
                    {/* Appoinment */}

                    {/* Product */}
                      {/* Category */}
                        {userPermission.includes('permission_view') && (<Route path="product-category-list" element={<ProductCategory/>} />)}
                        {userPermission.includes('permission_view') && (<Route path="product-category-add" element={<ProductCategoryAdd/>} />)}
                        {userPermission.includes('home_all_edit') && (<Route path="product-category-edit/:id" element={<ProductCategoryEdit/>} />)}
                      {/* Category */}

                      {/* Product */}
                        {userPermission.includes('product_view') && (<Route path="products-list" element={<ProductList/>} />)}
                        {userPermission.includes('product_add') && (<Route path="product-add" element={<ProductAdd/>} />)}
                        {userPermission.includes('product_edit') && (<Route path="product-edit/:id" element={<ProductEdit/>} />)}
                        {userPermission.includes('product_view') && (<Route path="product-review-list" element={<ReviewList/>} />)}
                      {/* Product */}
                    {/* Product */}

                    {/* product category */}
                      {userPermission.includes('faq_view') && (<Route path="pages/faq" element={<FaqList/>} />)}
                      {userPermission.includes('faq_add') && (<Route path="pages/faq-add" element={<FaqAdd/>} />)}
                      {userPermission.includes('faq_edit') && (<Route path="pages/faq-edit/:id" element={<FaqEdit/>} />)}
                    {/* category */}

                    {/* Bot Response */}
                      {userPermission.includes('bot_response_view') && (<Route path="pages/bot" element={<BotResponseList/>} />)}
                      {userPermission.includes('bot_response_add') && (<Route path="pages/bot-response-add" element={<BotResponseAdd/>} />)}
                      {userPermission.includes('bot_response_edit') && (<Route path="pages/bot-response-edit/:id" element={<BotResponseEdit/>} />)}
                    {/* Bot Response */}

                    {/* Note Response */}
                    {userPermission.includes('notice_view') && (<Route path="pages/notice" element={<NoteList/>} />)}
                      {userPermission.includes('notice_add') && (<Route path="pages/notice-add" element={<NoteAdd/>} />)}
                      {userPermission.includes('notice_edit') && (<Route path="pages/notice-edit/:id" element={<NoteEdit/>}/>)}
                    {/* Note Response */}

                    {/* Background Image */}
                    {userPermission.includes('image_view') && (<Route path="pages/background-image-list" element={<BackImageList/>} />)}
                      {userPermission.includes('image_add') && (<Route path="pages/background-image-add" element={<BackImageAdd/>} />)}
                      {userPermission.includes('image_edit') && (<Route path="pages/background-image-edit/:id" element={<BackImageEdit/>}/>)}
                    {/* Background Image */}
                {/* Pages Routes */}
              </Route>
            
          </>
        )}


        </Routes>
       
  );
}
