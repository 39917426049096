import React, { useEffect, useState } from 'react';
import { Api } from '../../api/Api';
import { useParams } from 'react-router-dom';
import { Link } from 'react-router-dom';
import { ServerUrl } from '../../api/ServerUrl';
import FadeInOut from '../../tools/FadeInOut';

const PublicationDetails = () => {

    useEffect(() => {
        document.title = 'Foot Balance Technology BD - Publications List Details';
      }, []);

    const { http } = Api();
    const { id } = useParams();
    const httpbackPage = ServerUrl;
    const [singleLayouts, setSingleLayouts] = useState({});
    const [blog, setBlog] = useState([]);
    const [backImage, setBackImage] = useState('');

    useEffect(() => {
        window.scrollTo(0, 0);
        http.get(`front-get-publication-list-single/${id}`)
            .then(res => { 
                setSingleLayouts(res.data.data);
                setBackImage(res.data.backImage);
            })
            .catch(error => {
                console.error('Error fetching layouts:', error);
            });
    }, [id]); 

    useEffect(() => {
        http.get('/front-get-publication-list')
            .then(res => {
                setBlog(res.data);
            })
            .catch(error => {
                console.error('Error fetching users:', error);
            });
    }, []);

    // Function to format time difference between two dates
    const formatTimeDifference = (dateString) => {
        const now = new Date();
        const pastDate = new Date(dateString);
        const timeDifference = now - pastDate;

        // Calculate time difference in seconds, minutes, hours, and days
        const seconds = Math.floor(timeDifference / 1000);
        const minutes = Math.floor(seconds / 60);
        const hours = Math.floor(minutes / 60);
        const days = Math.floor(hours / 24);

        if (days > 0) {
            return `${days} days ago`;
        } else if (hours > 0) {
            return `${hours} hours ago`;
        } else if (minutes > 0) {
            return `${minutes} minutes ago`;
        } else {
            return `a few seconds ago`;
        }
    };

      const truncateString = (str, num) => {
            if (str.length <= num) {
                return str;
            }
            return str.slice(0, num) + "...";
        }

    return (
        <div>
            <section className="page-title pageTitleBg" style={{backgroundImage: `url(${ServerUrl}/backend/img/back_image/${backImage.image})`}}>
                <div className="auto-container">
                    <h2>Publication</h2>
                    <ul className="page-breadcrumb">
                    <li><Link to="/">home</Link></li>
                        <li>Publication Details</li>
                    </ul>
                </div>
            </section>
            <div className="sidebar-page-container">
                <div className="auto-container">
                    <div className="row clearfix">
                        {/*Content Side / Services Detail */}
                        <div className="content-side col-xl-12 col-lg-12 col-md-12 col-sm-12">
                            <div className="services-detail">
                                <FadeInOut>
                                <div className="row inner-box">
                                    <div className="col-md-8 mt-5">
                                    <h3 className='mb-3'>{singleLayouts.title}</h3>
                                        <div className="row">
                                            <div className="col-md-12">
                                                <div className="image">
                                                    <img src={httpbackPage + '/backend/img/publications/' + singleLayouts.image} className='img-fluid' alt='' />
                                                </div>
                                            </div>
                                            <div className="content-column  col-md-12">
                                                <div className="inner-column">
                                                    <div className="text mb-3" dangerouslySetInnerHTML={{ __html: singleLayouts.description }}></div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    
                                    <div className="content-column  col-md-4 mt-4">
                                        <div className="inner-column">
                                            <div className="text-center"><h2>Latest Publications</h2></div>
                                            {blog.slice(0, 6).map((blogItem, index) => (
                                                <div key={index} className="news-block p-2">
                                                    <div className="inner-box wow fadeInRight" data-wow-delay="0ms" data-wow-duration="1500ms">
                                                        <div className="image">
                                                            <Link to={`/publication-details/${blogItem.id}`}>
                                                                <img src={`${httpbackPage}/backend/img/publications/${blogItem.image}`} alt="" />
                                                            </Link>
                                                        </div>
                                                        <div className="lower-content">
                                                            <ul className="post-meta">
                                                                <li>{formatTimeDifference(blogItem.created_at)}</li>
                                                            </ul>
                                                            <h3>
                                                                <Link to={`/publication-details/${blogItem.id}`}>
                                                                    <div dangerouslySetInnerHTML={{ __html: truncateString(blogItem.title, 60) }}></div> 
                                                                </Link>
                                                            </h3>
                                                            <Link to={`/publication-details/${blogItem.id}`} className="read-more">
                                                                Read more <span className="icon flaticon-right-arrow-1"></span>
                                                            </Link>
                                                        </div>
                                                    </div>
                                                </div>
                                            ))}
                                        </div>
                                    </div>
                                </div>
                                </FadeInOut>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}


export default PublicationDetails