import React, { useEffect } from 'react';

const BackLinksCss = () => {
  // const basePathFront = 'http://localhost:3000/';
  const basePathFront = 'https://www.footbalancetech.com/';
  
  useEffect(() => {
    // Array of stylesheet URLs
    const stylesheets = [
      `${basePathFront}assets/admin_assets/css/bootstrap.min.css`,
      `${basePathFront}assets/admin_assets/css/app.min.css`,
      `${basePathFront}assets/admin_assets/css/style.css`,
      `${basePathFront}assets/admin_assets/css/components.css`,
      `${basePathFront}assets/admin_assets/css/custom.css`
    ];

    // Create and append each stylesheet link
    const links = stylesheets.map(href => {
      const link = document.createElement('link');
      link.rel = 'stylesheet';
      link.href = href;
      document.head.appendChild(link);
      return link;
    });

    // Cleanup function to remove the links when the component unmounts
    return () => {
      links.forEach(link => document.head.removeChild(link));
    };
  }, []);

  return null; // No need to render anything
};

export default BackLinksCss;
