import React from 'react';
import { Outlet } from 'react-router-dom';
import Navbar from './backend/layouts/Navbar';
import Sidebar from './backend/layouts/Sidebar';
import BackLinksCss from './backend/layouts/BackLinksCss';

const AdminLayout = () => {
  return (
    <div>
      <BackLinksCss/>
      
      <Navbar />
      <Sidebar />
      <Outlet />


    </div>
  );
};

export default AdminLayout;
