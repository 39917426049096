import React from 'react';
import { IoMdArrowRoundBack } from "react-icons/io";

const BackButton = () => {
  const handleBack = () => {
    window.history.back(); // Navigate back using window.history
  };

  return (
    <button onClick={handleBack} style={{ background: 'none', border: 'none', fontSize: '15px' }}>
      <IoMdArrowRoundBack className='text-white rounded-circle bg-primary p-1' style={{fontSize: '30px' }}/>
    </button>
  );
};

export default BackButton;
