import React, { useEffect, useRef, useState } from 'react';
import { Link } from 'react-router-dom';
import 'datatables.net-dt/css/dataTables.dataTables.css';
import $ from 'jquery'; 
import 'datatables.net'; 
import {ServerUrl} from '../../../../api/ServerUrl';
import { ProtectAPi } from '../../../../api/ProtectAPi';
import swal from 'sweetalert';
import { SetPermission } from '../../../../auth/SetPermission';
import EditIcon from '../../../tools/EditIcon';
import DeleteIcon from '../../../tools/DeleteIcon';

const FhihList = () => {

    const { http } = ProtectAPi();
    const httpCPage = ServerUrl;
    const tableRef = useRef(null);
    const [layouts, setLayout] = useState([]);
    const userPermission = SetPermission();
  
    useEffect(() => {
      http.get('/get-pages-fhih-layouts')
        .then(res => { 
            setLayout(res.data);
        })
        .catch(error => {
          console.error('Error fetching users:', error);
        });
    }, []);
  
    
    useEffect(() => {
        if (layouts.length > 0 && !tableRef.current.DataTable) {
            $(tableRef.current).DataTable();
        } else {
            if ($.fn.DataTable.isDataTable('#table-1')) {
                $(tableRef.current).DataTable().destroy();
            }
        }
    }, [layouts]);


    // delete roles
    const handleDeleteRole = (roleId) => {
        swal({
            title: 'Are you sure?',
            text: 'Once deleted, you will not be able to recover this Layout!',
            icon: 'warning',
            buttons: true,
            dangerMode: true,
        }).then((willDelete) => {
            if (willDelete) {
                http.delete(`/delete-single-fhih-layout/${roleId}`)
                    .then(response => {
                        if (response.data.status !== 201) {
                            setLayout(prevLayouts => prevLayouts.filter(layout => layout.id !== roleId));
                            swal(response.data.msg, {
                                icon: response.data.alert,
                            });
                        } else {
                            swal('Oops! Something went wrong while deleting the Layout.', {
                                icon: 'error',
                            });
                        }
                    })
                    .catch(error => {
                        swal('Oops! Something went wrong while deleting the Layout.', {
                            icon: 'error',
                        });
                    });
            } else {
                swal('The Layout is safe!');
            }
        });
    };

  return (
    <div>
        <div className="main-content">
          <section className="section">
            <div className="section-body">
              <div className="row">
                <div className="col-12">
                  <div className="card">
                    <div className="card-body">
                        <div class="d-flex justify-content-between mb-3">
                            <h5>Fhih List</h5>
                            <div class="text-end">
                                <Link to='/admin/pages/fhih-add' className="btn btn-primary btn-sm">Add New Fhih</Link>
                            </div>
                        </div>
                      <div className="table-responsive">
                        <table className="table table-striped" id="table-1" ref={tableRef}>
                          <thead>
                            <tr>
                              <th className="text-center">#</th>
                              <th>Title</th>
                              <th>Priority</th>
                              <th>Image</th>
                              <th>Action</th>
                            </tr>
                          </thead>
                          <tbody>
                            {layouts.map((layout, index) => (
                              <tr key={layout.id}>
                                <td>{index + 1}</td>
                                <td>{layout.title}</td>
                                <td>{layout.priority}</td>
                                <td><img alt="" src={httpCPage + '/backend/img/fhih/' + layout.image} width="100" /></td>
                                <td>
                                {userPermission.includes('home_all_edit') && (<Link to={`/admin/pages/fhih-edit/${layout.id}`} className="editbtn mr-1">
                                      <button className="btn btn-warning">
                                        <EditIcon/>
                                      </button>
                                    </Link>
                                  )}
                                  {userPermission.includes('about_delete') && (<Link to='' className="editbtn">
                                        <button className="btn btn-danger" onClick={() => handleDeleteRole(layout.id)}>
                                            <DeleteIcon/>
                                        </button>
                                    </Link>
                                    )}
                                </td>
                              </tr>
                            ))}
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>
    </div>
  )
}

export default FhihList