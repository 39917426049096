import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import 'datatables.net-dt/css/dataTables.dataTables.css';
import 'datatables.net';
import { ProtectAPi } from '../../../../api/ProtectAPi';

const SmsPackage = () => {
  const { http } = ProtectAPi();
  const [smsSent, setSmsSent] = useState(null);
  const [smsSentMonth, setSmsSentMonth] = useState(null);
  const [monthYear, setMonthYear] = useState('');

  useEffect(() => {
    http.get('/sent-sms-check')
      .then(res => {
        setSmsSent(res.data.sms_sent);
      })
      .catch(error => {
        console.error('Error fetching users:', error);
      });
  }, []);

 

  const handleChange = (formattedValue) => {
    setMonthYear(formattedValue);

      http.get(`/sent-sms-check-month/${formattedValue}`)
        .then(res => {
            setSmsSentMonth(res.data.sms_sent);
        })
        .catch(error => {
            console.error('Error fetching users:', error);
      });
  };
    useEffect(() => {
        const now = new Date();
        const currentMonthYear = `${String(now.getMonth() + 1).padStart(2, '0')}-${now.getFullYear()}`;
        handleChange(currentMonthYear);
    }, []);

  return (
    <div>
      <div className="main-content">
        <section className="section">
          <div className="section-body">
            <div className="row">
              <div className="col-12">
                <div className="card card-primary">
                  <div className="card-body">
                      <div className="d-flex">
                          <div className="mb-2">
                              <Link to="/admin/pages/appoinment-list" className="btn border btn-sm mr-2">Pending List</Link>
                          </div>
                          <div className="mb-2">
                              <Link to="/admin/pages/appoinment-list-confirmed"  className="btn border btn-sm mr-2">Appoinment Confirmed</Link>
                          </div>
                          <div className="mb-2">
                              <Link to="/admin/pages/appoinment-list-today" className="btn border btn-sm mr-2">Appoinment Today</Link>
                          </div>
                          <div className="mb-2">
                              <Link to="/admin/pages/appoinment-scheduled-confirm" className="btn border btn-sm mr-2">Scheduled Confirm</Link>
                          </div>
                          <div className="mb-2">
                            <Link to="/admin/pages/sms-sent-quantity" className="btn btn-primary btn-sm mr-2">Sms Package</Link>
                         </div>
                      </div>
                  </div>
                </div>
                <div className="card">
                  <div className="card-body">
                    <div className="text-center w-50 mx-auto">
                        <div className="form-group">
                        <input 
                            type="month" 
                            className="form-control"
                            onChange={(e) => {
                                const value = e.target.value;
                                const formattedValue = value.split('-').reverse().join('-'); // Format as MM-YYYY
                                handleChange(formattedValue);
                            }}
                            value={monthYear.split('-').reverse().join('-')} // Display as YYYY-MM for the input
                        />
                        </div>
                        <p>Selected Month-Year: {monthYear}</p>
                    </div>

                    <div className="row">
                        <div className="col-md-6">
                            <div className="card">
                                <div className="card-body">
                                    <p>Selected Month</p>
                                    <div className="text-center mt-2">
                                        <span className='display-3 fw-bold'>{smsSentMonth} </span> <br />
                                        <span className='fw-bold' style={{fontSize:'10px'}}>Total</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-6">
                            <div className="card">
                                <div className="card-body">
                                    <div className="text-center mt-5">
                                        <span className='display-3 fw-bold'>{smsSent} </span> <br />
                                        <span className='fw-bold' style={{fontSize:'10px'}}>Sub Total</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </div>
  )
}

export default SmsPackage;
