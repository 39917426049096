import React, { useEffect, useState } from 'react';
import Offcanvas from 'react-bootstrap/Offcanvas';
import { Link } from 'react-router-dom';
import { FaChevronDown } from 'react-icons/fa';
import { IoIosSearch } from "react-icons/io";
import { ServerUrl } from '../../api/ServerUrl';
import { Api } from '../../api/Api';
import { FaEye } from "react-icons/fa";
import FadeInOut from '../../tools/FadeInOut';
import { FrontUrl } from '../../api/FrontUrl';

const ProductList = () => {

    useEffect(() => {
        document.title = 'Foot Balance Technology BD - Product list';
      }, []);


    const httpCPage = ServerUrl;
    const { http } = Api();
    const [showCategories, setShowCategories] = useState(false);
    const [activeCategory, setActiveCategory] = useState(null);
    const [productCategory, SetProductCategory] = useState([]);
    const [allProduct, setAllProduct] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const [categoryId, setCategoryId] = useState(null);
    const [totalPages, setTotalPages] = useState(1);
    const [totalProduct, setTotalProductCount] = useState('');
    const [search, setSearch] = useState('');
    const itemsPerPage = 12;
    const [backImage, setBackImage] = useState('');
    const [ProductPageListInfo, setProductPageListInfo] = useState([]);
    const [productPagesList, setProductPagesList] = useState([]);
    const [productPageShowing, setProductPageShowing] = useState(1);
    const [loader, setLoader] = useState('off');
    const [activeDropdownPage, setActiveDropdownPage] = useState(null);
    const [activeDropdownProduct, setActiveDropdownProduct] = useState(null);
    const [activeDropdownLi, setActiveDropdownLi] = useState(null);
    const [productPageData, setProductPageData] = useState(null);


    useEffect(() => {
        http.get('/front-product-category')
          .then(res => { 
            SetProductCategory(res.data.productCategories);
            setBackImage(res.data.backImage);
          })
          .catch(error => {
            console.error('Error fetching users:', error);
          });
    }, []);

    useEffect(() => {
        http.get('/front-get-product-pages')
            .then(res => { 
                setProductPagesList(res.data);
            })
            .catch(error => {
                console.error('Error fetching users:', error);
            });
    }, []);

    useEffect(() => {
        window.scrollTo(0, 0);
        fetchData(categoryId);
    }, [currentPage]);

    const fetchData = (category, searchs) => {
        setCategoryId(category)
        const page = currentPage;
        const limit = itemsPerPage;
        http.get(`/front-get-all-product?page=${page}&limit=${limit}&category_id=${category  ? category : null}&search=${searchs ? searchs :null}`)
            .then(res => {
                setAllProduct(res.data.products || []);
                setTotalPages(Math.ceil(res.data.totalCount / limit));
                setTotalProductCount(res.data.totalCount);
            })
            .catch(error => {
                console.error('Error fetching blogs:', error);
            });
    };

    const paginate = pageNumber => {
        setCurrentPage(pageNumber);
    };

    const nextPage = () => {
        if (currentPage < totalPages) {
            setCurrentPage(currentPage + 1);
        }
    };

    const prevPage = () => {
        if (currentPage > 1) {
            setCurrentPage(currentPage - 1);
        }
    };

    const renderPaginationButtons = () => {
        const visiblePages = [];
        const numVisibleButtons = 5;
        const halfVisibleButtons = Math.floor(numVisibleButtons / 2);
    
        if (totalPages <= numVisibleButtons) {
            return Array.from({ length: totalPages }, (_, index) => index + 1);
        }
    
        let startPage = Math.max(1, currentPage - halfVisibleButtons);
        let endPage = Math.min(totalPages, startPage + numVisibleButtons - 1);
    
        while (endPage - startPage + 1 < numVisibleButtons) {
            if (startPage > 1) {
                startPage--;
            } else if (endPage < totalPages) {
                endPage++;
            }
        }
    
        for (let i = startPage; i <= endPage; i++) {
            visiblePages.push(i);
        }
    
        return visiblePages;
    };


    const toggleCategories = () => {
        setShowCategories(!showCategories);
    };
    const handleCategoryClick = (category) => {
        setActiveDropdownProduct(category);
        setActiveDropdownPage(null);
        setProductPageShowing(1);
        if (activeCategory === category) {
        setActiveCategory(null); 
        } else {
        setActiveCategory(category);
        }
    };

    const getDataByCategory = (category) =>  {
        setActiveDropdownLi(category);
        setActiveDropdownPage(null);
        setShowCategories(false);
         fetchData(category);
    }

    const handleSearchChange = (event) => {
        setProductPageShowing(1);
        const { value } = event.target;
        setSearch(value);
        fetchData(categoryId, value); // Call fetchData with the updated search value
    };

    const getAllData = () => {
        setProductPageShowing(1);
        setActiveDropdownProduct(null);
        setActiveDropdownLi(null);
        setActiveDropdownPage(null);
        setShowCategories(false);
        setCurrentPage(1);
        fetchData();
    }

    const getProductPagesData = (id) => {
        setLoader('on');
        setActiveDropdownPage(id);
        setActiveDropdownProduct(null);
        setActiveDropdownLi(null);
        setShowCategories(false);
        setProductPageShowing(0);
        http.get(`/front-get-all-product-pages-list/${id}`)
            .then(res => {
                setLoader('off');
                setProductPageListInfo(res.data);
            })
            .catch(error => {
                console.error('Error fetching blogs:', error);
            });
    };

    return (
        <div>
            <section className="page-title pageTitleBg" style={{backgroundImage: `url(${ServerUrl}/backend/img/back_image/${backImage.image})`}}>
                <div className="auto-container">
                    <h2>Products</h2>
                    <ul className="page-breadcrumb">
                    <li><Link to="/">home</Link></li>
                        <li>Products</li>
                    </ul>
                </div>
            </section>
            <div className="productPage pt-5">
                <div className="container">
                    <div className="">
                        <div className="row">
                            <div className="col-3 col-md-3">
                                <button className='theme-btn btn-style-cus FilterButton' onClick={toggleCategories}>Filter</button>
                            </div>
                            <div className="col-md-6 col-1"></div>
                            <div className="col-md-3 col-8">
                                <div className="input-group mt-1">
                                    <input 
                                        type="text" 
                                        placeholder='Search Product' 
                                        className='form-control border' 
                                        value={search} 
                                        onChange={handleSearchChange} 
                                    />
                                    <div className="input-group-append">
                                        <span className="input-group-text border-squre"><IoIosSearch /></span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <section className="shop-section">
                        <div className="">
                        
                        <div className="title-box">
                            <h2>Featured Products</h2>
                        </div>
                            
                            <div className="row">
                                <div className="col-md-3">
                                    <FadeInOut>
                                    <div className="categoryBox">
                                        <h4>Category</h4>
                                        <hr />
                                        <div className="categoryList">
                                           <div>
                                                <div className="CategoryTitle">
                                                    <button className='theme-btn btn-style-cus my-2'  onClick={getAllData}>Show All</button>
                                                </div>
                                                {Object.keys(productCategory).map((group_name, index) => (
                                                    <div key={index} className={`categoryItem ${activeCategory === group_name.toLowerCase() ? 'active' : ''} ${activeDropdownProduct === group_name.toLowerCase() ? 'active' : ''}`} onClick={() => handleCategoryClick(group_name.toLowerCase())}>
                                                        <div className="CategoryTitle">
                                                            <span>{group_name}</span>
                                                            <FaChevronDown className={`arrowIcon ${activeCategory === group_name.toLowerCase() ? 'rotateArrow' : ''}`} />
                                                        </div>
                                                        <ul>
                                                            {productCategory[group_name].map((category, categoryIndex) => (
                                                                <li key={categoryIndex} onClick={() => {getDataByCategory(category.id)}} className={`${activeDropdownLi === category.id ? 'CategoryLiActive' : ''}`}>{category.name}  </li>
                                                            ))}
                                                        </ul>
                                                    
                                                    </div>
                                                ))}
                                                 <div>
                                                    <ul>
                                                        {productPagesList.map(data => (
                                                            <li key={data.id}  onClick={() => {getProductPagesData(data.id)}} className={`categoryItem rounded ${activeDropdownPage === data.id ? 'activeCategoryItem' : ''}`}>{data.title}</li>
                                                        ))}
                                                    </ul>
                                                 </div>
                                            </div>
                                            
                                        </div>
                                    </div>
                                    </FadeInOut>
                                </div>
                                {productPageShowing === 1 && (
                                    <div className="col-md-9">
                                        
                                    <span className='mb-4 d-block'>Showing {totalProduct} Products </span>
                                    <div className="row clearfix">
                                       
                                    <div className="d-flex justify-content-center">
                                    <div className="row">
                                    {allProduct && allProduct.length > 0 ? (
                                        allProduct.map((product, index) => (
                                            <div className="shop-item col-lg-4 col-md-6 col-sm-12" key={index}>
                                                <FadeInOut>
                                                <div className="inner-box productBox">
                                                <p className='productCategoryNameTop2'>{product.category_name}</p>
                                                    <div className="image">
                                                        <Link to={`/product-details/${product.id}`}>
                                                            <img src={`${httpCPage}/backend/img/product/${product.images[0].image}`} alt={product.product_name} />
                                                        </Link>
                                                        <div className="overlay-box">
                                                            <ul className="option-box">
                                                                <li>
                                                                    <Link to={`/product-details/${product.id}`} className="lightbox-image" data-fancybox="products">
                                                                        <FaEye />
                                                                    </Link>
                                                                </li>
                                                            </ul>
                                                        </div>
                                                    </div>
                                                    <div className="lower-content">
                                                        <h3><Link to={`/product-details/${product.id}`}>{product.product_name}</Link></h3>
                                                    </div>
                                                </div>
                                                </FadeInOut>
                                            </div>
                                        ))
                                    ) : (
                                        <div>No product found</div>
                                    )}
                                    </div>
                                    </div>

                                      
                                    </div>
                                </div>
                                )}
                                {productPageShowing === 0 && (
                                   <div className="col-md-9 productPagesInfo">
                                        {loader === 'on' &&
                                            <div className="text-center">
                                                <img src={`${FrontUrl}/assets/images/loader.svg`} style={{width:'50px', marginTop: '20px'}} alt="" />
                                            </div>
                                        }
                                        {loader === 'off' &&
                                            <div>
                                                <h3 className='text-center fw-bold'>{ProductPageListInfo.title}</h3>
                                                 <div className="text mb-3" dangerouslySetInnerHTML={{ __html: ProductPageListInfo.description }}></div>
                                            </div>
                                        }
                                   </div>
                                )}
                                
                            </div>
                        </div>
                    </section>
                    <FadeInOut>
                    {productPageShowing === 1 && (
                    <div className="d-flex justify-content-center">
                        <nav aria-label="Page navigation example">
                            <ul className="pagination mb-5">
                                <li className="page-item">
                                    <button className="pegibutton NxtPrevButton me-1" onClick={prevPage}>Previous</button>
                                </li>
                                {renderPaginationButtons().map((page, index) => (
                                    <li key={index} className={` page-item ${currentPage === page ? 'paginateButton' : ''}`}>
                                        <button className="pagiItem pegibutton" onClick={() => paginate(page)}>{page}</button>
                                    </li>
                                ))}
                                <li className="page-item">
                                    <button className="pegibutton NxtPrevButton ms-1" onClick={nextPage}>Next</button>
                                </li>
                            </ul>
                        </nav>
                    </div>
                     )}
                    </FadeInOut>
                    
                </div>
            </div>

            <Offcanvas  className="custom-offcanvas" show={showCategories} onHide={toggleCategories} placement="start">
                <Offcanvas.Header closeButton>
                    <Offcanvas.Title className="text-uppercase">filter</Offcanvas.Title>
                </Offcanvas.Header>
                <Offcanvas.Body>
                    <div className="categoryBoxSmall">
                        <div>
                            <div className="CategoryTitle">
                                <button className='theme-btn btn-style-cus my-2'  onClick={getAllData}>Show All</button>
                            </div>
                            {Object.keys(productCategory).map((group_name, index) => (
                                <div key={index} className={`categoryItem ${activeCategory === group_name.toLowerCase() ? 'active' : ''} ${activeDropdownProduct === group_name.toLowerCase() ? 'active' : ''}`} onClick={() => handleCategoryClick(group_name.toLowerCase())}>
                                    <div className="CategoryTitle">
                                        <span>{group_name}</span>
                                        <FaChevronDown className={`arrowIcon ${activeCategory === group_name.toLowerCase() ? 'rotateArrow' : ''}`} />
                                    </div>
                                    <ul>
                                        {productCategory[group_name].map((category, categoryIndex) => (
                                            <li key={categoryIndex} onClick={() => {getDataByCategory(category.id)}} className={`${activeDropdownLi === category.id ? 'CategoryLiActive' : ''}`}>{category.name}</li>
                                        ))}
                                    </ul>
                                </div>
                            ))}
                             <div>
                                <ul>
                                    {productPagesList.map(data => (
                                        <li key={data.id}  onClick={() => {getProductPagesData(data.id)}} className={`categoryItem rounded ${activeDropdownPage === data.id ? 'activeCategoryItem' : ''}`}>{data.title}</li>
                                    ))}
                                </ul>
                            </div>
                           
                        </div>
                    </div>
                </Offcanvas.Body>
            </Offcanvas>
        </div>
    );
};

export default ProductList;
