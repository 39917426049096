import React, { useEffect, useRef, useState } from 'react';
import { Link } from 'react-router-dom';
import 'datatables.net-dt/css/dataTables.dataTables.css';
import $ from 'jquery'; 
import 'datatables.net'; 
import swal from 'sweetalert';
import { ProtectAPi } from '../../../api/ProtectAPi';
import {ServerUrl} from '../../../api/ServerUrl';
import { SetPermission } from '../../../auth/SetPermission';
import EditIcon from '../../tools/EditIcon';
import ShowIcon from './../../tools/ShowIcon';
import DeleteIcon from '../../tools/DeleteIcon';

const UserList = () => {
  const { http } = ProtectAPi();
  const httpCPage = ServerUrl;
  const tableRef = useRef(null);
  const [users, setUsers] = useState([]);
  const userPermission = SetPermission();

  useEffect(() => {
    http.get('/get-all-user')
      .then(res => { 
        setUsers(res.data);
      })
      .catch(error => {
        console.error('Error fetching users:', error);
      });
  }, []);

  useEffect(() => {
    if (users.length > 0 && !tableRef.current.DataTable) {
      $(tableRef.current).DataTable();
    }
  }, [http]);

  // delete roles
  const handleDeleteRole = (roleId) => {
    swal({
        title: 'Are you sure?',
        text: 'Once deleted, you will not be able to recover this user!',
        icon: 'warning',
        buttons: true,
        dangerMode: true,
    }).then((willDelete) => {
        if (willDelete) {
            http.delete(`/delete-user/${roleId}`)
               .then(response => {
                   if(response.data.status !== 201){
                    setUsers(prevRoles => prevRoles.filter(data => data.id !== roleId));
                   }
                    swal(response.data.msg, {
                        icon: response.data.alert,
                    });
               })
               .catch(error => {
                    swal('Oops! Something went wrong while deleting this item.', {
                        icon: 'error',
                    });
               });
            swal('Poof! This user has been deleted!', {
                icon: 'success',
            });
        } else {
            swal('This user is safe!');
        }
    });
};

  return (
    <div>
      <div className="main-content">
        <section className="section">
          <div className="section-body">
            <div className="row">
              <div className="col-12">
                <div className="card">
                  <div className="card-body">
                    <div class="d-flex justify-content-between mb-3">
                          <h5>User List</h5>
                          <div class="text-end">
                              <Link to="/admin/users-create" class="btn btn-sm btn-primary"><i class="fas fa-plus"></i> Create User</Link>
                          </div>
                      </div>
                    <div className="table-responsive">
                      <table className="table table-striped" id="table-1" ref={tableRef}>
                        <thead>
                          <tr>
                            <th className="text-center">#</th>
                            <th>Name</th>
                            <th>Email</th>
                            <th>Image</th>
                            <th>Action</th>
                          </tr>
                        </thead>
                        <tbody>
                          {users.map((user, index) => (
                            <tr key={index}>
                              <td>{index + 1}</td>
                              <td>{user.name}</td>
                              <td>{user.email}</td>
                              <td>
                                <img alt="" src={httpCPage + '/backend/user_image/' + user.image} width="35" />
                              </td>
                              <td>
                                {userPermission.includes('user_edit') && (<Link to={`/admin/users-edit/${user.id}`} className="editbtn mr-1">
                                    <button className="btn btn-warning">
                                      <EditIcon/>
                                    </button>
                                  </Link>
                                )}
                                {userPermission.includes('user_view') && (<Link to={`/admin/users-show/${user.id}`} className="editbtn mr-1"><button className="btn btn-primary"><ShowIcon/></button></Link>)}
                                {userPermission.includes('user_delete') && (<Link to='' className="editbtn">
                                        <button className="btn btn-danger" onClick={() => handleDeleteRole(user.id)}>
                                            <DeleteIcon/>
                                        </button>
                                    </Link>
                                    )}
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </div>
  );
};

export default UserList;
