import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
import 'datatables.net-dt/css/dataTables.dataTables.css';
import 'datatables.net';
import swal from 'sweetalert';
import { ProtectAPi } from '../../../../api/ProtectAPi';
import Select from 'react-select';
import { addDays, format } from 'date-fns';

const ScheduledConfirm = () => {
  const { http } = ProtectAPi();
  const navigate = useNavigate();
  const [tableData, setTableData] = useState([]);

  useEffect(() => {
    http.get('/get-pages-appoinment-scheduled-confirm')
      .then(res => {
        setTableData(res.data);
      })
      .catch(error => {
        console.error('Error fetching users:', error);
      });
  }, []);

  const [selectedPendingPatients, setSelectedPendingPatients] = useState([]);
  const [selectAll, setSelectAll] = useState(false);

  const generateTimeSlots = () => {
    const timeSlots = [];
    for (let hour = 9; hour <= 22; hour++) {
      for (let minute = 0; minute < 60; minute += 30) {
        const formattedHour = hour > 12 ? hour - 12 : hour;
        const amPm = hour >= 12 ? 'PM' : 'AM';
        const formattedMinute = minute === 0 ? '00' : minute;
        const time = `${formattedHour}:${formattedMinute} ${amPm}`;
        timeSlots.push({ label: time, value: time });
      }
    }
    return timeSlots;
  };

  const numPatients = tableData.length;

  const handlePendingPatientsChange = (selectedOptions) => {
    setSelectedPendingPatients(selectedOptions);
    setSelectAll(false);
  };

  const handleSelectAllChange = () => {
    setSelectAll(true);
    setSelectedPendingPatients([]);
  };

  const options = [{ value: 'all', label: 'All' }];

  const handleDateChange = (event) => {
    const selectedDate = event.target.value;
    if (selectedDate) {
      setFormData({...formData, confirm_date: selectedDate})
    }
  };

  const [formData, setFormData] = useState({
    patientId: [],
    allPatient: '',
    confirm_date: '',
    confirm_time: ''
  });

  const handleTimeChange = (selectedOption) => {
    setFormData({ ...formData, confirm_time: selectedOption.value });
  };

  const [loader, setLoader] = useState('off');
  const [errors, setErrors] = useState({});

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData(prevState => ({
      ...prevState,
      [name]: value
    }));
  };

  const submitForm = () => {
    setLoader('on');
    setErrors({});
    
    const selectedPatientIds = selectedPendingPatients.map(patient => patient.value);
    const selectedConfirmDate = formData.confirm_date;
    const selectedConfirmTime = formData.confirm_time;

    const smsData = {
      patientId: selectAll ? 'all' : selectedPatientIds,
      confirmDate: selectedConfirmDate,
      confirmTime: selectedConfirmTime
    };

    http.post('/get-pages-appoinment-scheduled-store', smsData)
      .then(res => {
        if (res.data.status === '422') {
          setLoader('off');
          swal('Success', res.data.msg, 'success');
          setFormData(prevState => ({
            ...prevState,
            patientId: selectedPatientIds,
            allPatient: selectAll ? 'all' : '', 
            confirm_date: formData.confirm_date,
            confirm_time: formData.confirm_time,
          }));
        } else if(res.data.status === '425'){
          setLoader('off');
          swal('Warning', res.data.error, 'warning');
          setFormData(prevState => ({
            ...prevState,
            patientId: selectedPatientIds,
            allPatient: selectAll ? 'all' : '', 
            confirm_date: formData.confirm_date,
            confirm_time: formData.confirm_time,
          }));
        } else {
          setLoader('off');
          setFormData({
            patientId: '',
            allPatient: '',
            confirm_date: '',
            confirm_time: ''
          });
          navigate('/admin/pages/appoinment-list-confirmed');
          swal(res.data.alert, res.data.msg, res.data.alert);
        }
      })
      .catch(error => {
        console.error('Error:', error);
      });
  };

  return (
    <div>
      <div className="main-content">
        <section className="section">
          <div className="section-body">
            <div className="row">
              <div className="col-12">
                <div className="card card-primary">
                  <div className="card-body">
                      <div className="d-flex">
                          <div className="mb-2">
                              <Link to="/admin/pages/appoinment-list" className="btn border btn-sm mr-2">Pending List</Link>
                          </div>
                          <div className="mb-2">
                              <Link to="/admin/pages/appoinment-list-confirmed"  className="btn border btn-sm mr-2">Appoinment Confirmed</Link>
                          </div>
                          <div className="mb-2">
                              <Link to="/admin/pages/appoinment-list-today" className="btn border btn-sm mr-2">Appoinment Today</Link>
                          </div>
                          <div className="mb-2">
                              <Link to="/admin/pages/appoinment-scheduled-confirm" className="btn btn-primary btn-sm mr-2">Scheduled Confirm</Link>
                          </div>
                          <div className="mb-2">
                            <Link to="/admin/pages/sms-sent-quantity" className="btn border btn-sm mr-2">Sms Package</Link>
                          </div>
                      </div>
                  </div>
                </div>
                <div className="card">
                  <div className="card-body">
                    <div class="d-flex justify-content-between mb-3">
                      <h5></h5>
                    </div>
                    <div className="row">
                      <div className="col-md-6">
                        <div className="form-group">
                          <label>Pending patient list {numPatients}</label>
                          <Select
                            options={tableData.map(data => ({ value: data.id, label: data.name }))}
                            isMulti
                            value={selectedPendingPatients}
                            onChange={handlePendingPatientsChange}
                          />
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="form-group">
                          <label>Select All</label>
                          <Select
                            options={options}
                            value={selectAll ? { value: 'all', label: 'All' } : null}
                            onChange={handleSelectAllChange}
                          />
                        </div>
                      </div>

                      <div className="col-md-6">
                        <div className="form-group mt-1">
                          <label>Select Appointment Date</label>
                          <input 
                            type="date" 
                            className="form-control" 
                            onChange={handleDateChange} 
                          />
                        </div>
                      </div>

                      <div className="col-md-6">
                        <div className="form-group row mb-4 p-1">
                          <label className="col-form-label">Time</label>
                          <Select
                            className="w-100"
                            options={generateTimeSlots()}
                            onChange={handleTimeChange}
                            value={formData.confirm_time ? { label: formData.confirm_time, value: formData.confirm_time } : null}
                            placeholder="Select"
                          />
                        </div>
                      </div>

                      <div className="col-md-12">
                          <div className="text-center">
                          <label className="col-form-label"></label>
                          {loader === 'off' ? (
                              <button type="submit" name="submit-form" onClick={submitForm} className="btn btn-primary mt-4">Submit</button>
                          ) : (
                              <div className="spinner-border text-dark mt-4" role="status"></div>
                          )}
                          </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </div>
  )
}

export default ScheduledConfirm;
