import React, { useEffect, useState } from 'react';
import { Api } from '../../api/Api';
import { Link, useParams } from 'react-router-dom';
import { ServerUrl } from '../../api/ServerUrl';
import FadeInOut from '../../tools/FadeInOut';

const FhihDetails = () => {
  useEffect(() => {
    document.title = 'Foot Balance Technology BD - Foot Health Information Hub Details';
  }, []);

  const { http } = Api();
  const { id } = useParams();
  const httpbackPage = ServerUrl;
  const [singleLayouts, setSingleLayouts] = useState({});
  const [backImage, setBackImage] = useState('');

  useEffect(() => {
    http.get(`foot-health-information-hub/details/${id}`)
      .then(res => { 
        setSingleLayouts(res.data.data);
        setBackImage(res.data.backImage);
      })
      .catch(error => {
        console.error('Error fetching layouts:', error);
      });
  }, [id]); // Added id as dependency to re-fetch data when id changes

  return (
    <div>
      <section className="page-title pageTitleBg" style={{backgroundImage: `url(${ServerUrl}/backend/img/back_image/${backImage.image})`}}>
        <div className="auto-container">
          <h2>{singleLayouts.title}</h2>
          <ul className="page-breadcrumb">
            <li><Link to="/">home</Link></li>
            <li>Services</li>
            <li>{singleLayouts.title}</li>
          </ul>
        </div>
      </section>
      <FadeInOut>
        <div className="sidebar-page-container cusmaincolor">
          <div className="auto-container">
            <div className="row clearfix">
              {/*Content Side / Services Detail */}
              <div className="content-side col-xl-12 col-lg-12 col-md-12 col-sm-12">
                <div className="services-detail">
                  <div className="row inner-box">
                    <div className="column col-lg-4 col-md-4 col-sm-12">
                      <div className="image">
                        <img src={httpbackPage + '/backend/img/fhih/' + singleLayouts.image} alt='' />
                      </div>
                    </div>
                    <div className="content-column col-lg-8 col-md-8 col-sm-12">
                      <div className="inner-column">
                        <h2 className='cusColorTitle'>{singleLayouts.title}</h2>
                        <div className="text mb-3" dangerouslySetInnerHTML={{ __html: singleLayouts.description }}></div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </FadeInOut>
    </div>
  );
};

export default FhihDetails;
