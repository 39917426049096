import React, { useEffect, useState } from 'react';
import { Api } from '../../api/Api';
import { Link } from 'react-router-dom';
import { ServerUrl } from '../../api/ServerUrl';
import FadeInOut from '../../tools/FadeInOut';

const Service = () => {

    useEffect(() => {
        document.title = `Foot Balance Technology BD - Services List`;
      }, []);

    const { http } = Api();
    const [tableData, setTableData] = useState([]);
    const httpbackPage = ServerUrl;
    const [backImage, setBackImage] = useState('');
    const [currentPage, setCurrentPage] = useState(1);
    const [totalPages, setTotalPages] = useState(1);
    const itemsPerPage = 6;
    const [loading, setLoading] = useState(false);

      useEffect(() => {
        fetchData();
    }, [currentPage]);

    const fetchData = () => {
        setLoading(true);
        const page = currentPage; // Current page
        const limit = itemsPerPage; // Items per page
    
        http.get(`/get-services-list?page=${page}&limit=${limit}`)
            .then(res => {
                setTableData(res.data.data || []);
                setTotalPages(Math.ceil(res.data.totalCount / limit));
                setBackImage(res.data.backImage);
            })
            .catch(error => {
                console.error('Error fetching blogs:', error);
            })
            .finally(() => {
                setLoading(false);
            });
    };

      const paginate = pageNumber => {
        setCurrentPage(pageNumber);
    };

    const nextPage = () => {
        if (currentPage < totalPages) {
            setCurrentPage(currentPage + 1);
        }
    };

    const prevPage = () => {
        if (currentPage > 1) {
            setCurrentPage(currentPage - 1);
        }
    };

      const renderPaginationButtons = () => {
        const visiblePages = [];
        const numVisibleButtons = 5;
        const halfVisibleButtons = Math.floor(numVisibleButtons / 2);
    
        if (totalPages <= numVisibleButtons) {
            return Array.from({ length: totalPages }, (_, index) => index + 1);
        }
        let startPage = Math.max(1, currentPage - halfVisibleButtons);
        let endPage = Math.min(totalPages, startPage + numVisibleButtons - 1);
        while (endPage - startPage + 1 < numVisibleButtons) {
            if (startPage > 1) {
                startPage--;
            } else if (endPage < totalPages) {
                endPage++;
            }
        }
        for (let i = startPage; i <= endPage; i++) {
            visiblePages.push(i);
        }
        return visiblePages;
    };

      const truncateString = (str, num) => {
            if (str.length <= num) {
                return str;
            }
            return str.slice(0, num) + "...";
        }


  return (
    <div>
        <section className="page-title pageTitleBg" style={{backgroundImage: `url(${ServerUrl}/backend/img/back_image/${backImage.image})`}}>
            <div className="auto-container">
                <h2>Services</h2>
                <ul className="page-breadcrumb">
                <li><Link to="/">home</Link></li>
                <li>Services</li>
                </ul>
            </div>
        </section>


        <section className="services-page-section style-two">
            <div className="auto-container">
                {/* Sec Title */}
                <FadeInOut>
                <div className="sec-title centered">
                    <h2>We Provide Services Based on Customer Needs</h2> <br />
                    <h5 className="text-center">At Foot Balance Technology, we recognize that every customer has unique foot requirements, which is why we have developed a comprehensive and rigorous process for creating customized shoes tailored to meet those needs. Our process is divided into three phases: Clinical Assessment, Clinical Observation, and Clinical Guidelines.</h5>
                </div>
                </FadeInOut>
                <div className="row clearfix">
                    {/* Service Block */}
                    {tableData.map((data) => (
                        <div key={data.id} className="service-block-three style-two col-lg-4 col-md-6 col-sm-12">
                            <FadeInOut>
                            <div className="inner-box wow fadeInUp" data-wow-delay="0ms" data-wow-duration="1500ms">
                                <div className="image">
                                    <Link to={`/our-services/details/${data.id}`}><img src={httpbackPage + '/backend/img/services/' + data.image} alt='' /></Link>
                                </div>
                                <div className="lower-content">
                                    <h3><Link to={`/our-services/details/${data.id}`}>{truncateString(data.title, 25)}</Link></h3>
                                    <div className="text mb-3" dangerouslySetInnerHTML={{ __html: truncateString(data.description, 70) }}></div>
                                    <span><Link to={`/our-services/details/${data.id}`} className="read-more">Read more</Link></span>
                                </div>
                            </div>
                            </FadeInOut>
                        </div>
                    ))}
                </div>
            </div>
            <FadeInOut>
                <div className="d-flex justify-content-center mb-4">
                    <nav aria-label="Page navigation example">
                        <ul className="pagination">
                            <li className="page-item">
                                <button className="pegibutton NxtPrevButton me-1" onClick={prevPage}>Previous</button>
                            </li>
                            {renderPaginationButtons().map((page, index) => (
                                <li key={index} className={` page-item ${currentPage === page ? 'paginateButton' : ''}`}>
                                    <button className="pagiItem pegibutton" onClick={() => paginate(page)}>{page}</button>
                                </li>
                            ))}
                            <li className="page-item">
                                <button className="pegibutton NxtPrevButton ms-1" onClick={nextPage}>Next</button>
                            </li>
                        </ul>
                    </nav>
                </div>
                </FadeInOut>
        </section>

    </div>
  )
}

export default Service