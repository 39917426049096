import React, { useEffect, useRef, useState } from 'react';
import { Link } from 'react-router-dom';
import 'datatables.net-dt/css/dataTables.dataTables.css';
import $ from 'jquery'; 
import 'datatables.net'; 
import swal from 'sweetalert';
import { ProtectAPi } from '../../../../api/ProtectAPi';
import { SetPermission } from '../../../../auth/SetPermission';
import EditIcon from '../../../tools/EditIcon'
import DeleteIcon from '../../../tools/DeleteIcon'

const ReviewList = () => {

      
  const { http } = ProtectAPi();
  const tableRef = useRef(null);
  const [tableData, setTableData] = useState([]);
  const userPermission = SetPermission();

  useEffect(() => {
    http.get('/get-product-review-list')
      .then(res => { 
        setTableData(res.data.review);
      })
      .catch(error => {
        console.error('Error fetching users:', error);
      });
  }, []);

  
  useEffect(() => {
      if (tableData.length > 0 && !tableRef.current.DataTable) {
          $(tableRef.current).DataTable();
      } else {
          if ($.fn.DataTable.isDataTable('#table-1')) {
              $(tableRef.current).DataTable().destroy();
          }
      }
  }, [tableData]);


  // delete roles
  const handleDeleteRole = (roleId) => {
      swal({
          title: 'Are you sure?',
          text: 'Once deleted, you will not be able to recover this item!',
          icon: 'warning',
          buttons: true,
          dangerMode: true,
      }).then((willDelete) => {
          if (willDelete) {
              http.delete(`/delete-product-review-single/${roleId}`)
                 .then(response => {
                     if(response.data.status !== 201){
                      setTableData(prevRoles => prevRoles.filter(data => data.id !== roleId));
                     }
                      swal(response.data.msg, {
                          icon: response.data.alert,
                      });
                 })
                 .catch(error => {
                      swal('Oops! Something went wrong while deleting this item.', {
                          icon: 'error',
                      });
                 });
              swal('Poof! This item has been deleted!', {
                  icon: 'success',
              });
          } else {
              swal('This item is safe!');
          }
      });
  };

  // delete roles
  const approveReview = (roleId) => {
    swal({
        title: 'Are you sure?',
        text: 'Once Clicked, This review will be approved',
        icon: 'warning',
        buttons: true,
        dangerMode: true,
    }).then((willDelete) => {
        if (willDelete) {
            http.post(`/update-review-status/${roleId}`)
               .then(response => {
                   if(response.data.status !== 201){
                    setTableData(prevRoles => prevRoles.filter(data => data.id !== roleId));
                   }
                    swal(response.data.msg, {
                        icon: response.data.alert,
                    });
               })
               .catch(error => {
                    swal('Oops! Something went wrong while deleting this review.', {
                        icon: 'error',
                    });
               });
            swal('Poof! This review has been approved!', {
                icon: 'success',
            });
        } else {
            swal('This review is still not approved!');
        }
    });
};

  return (
    <div>
        <div className="main-content">
          <section className="section">
            <div className="section-body">
              <div className="row">
                        <div className="col-12">
                            <div className="card">
                                <div className="card-body">
                                    <div class="d-flex justify-content-between mb-3">
                                        <h5>Review List</h5>
                                    </div>
                                <div className="table-responsive">
                                <table className="table table-striped" id="table-1" ref={tableRef}>
                                    <thead>
                                        <tr>
                                        <th className="text-center">#</th>
                                        <th>Name</th>
                                        <th>Email</th>
                                        <th>Review</th>
                                        <th>Ratings</th>
                                        <th>Action</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {tableData.map((data, index) => (
                                        <tr key={data.id}>
                                            <td>{index + 1}</td>
                                            <td>{data.name}</td>
                                            <td>{data.email}</td>
                                            <td>{data.comments}</td>
                                            <td>{data.rating}</td>
                                            <td>
                                            <Link to='' className="editbtn mr-2">
                                                    <button className="btn btn-success" onClick={() => approveReview(data.id)}>
                                                        <EditIcon/>
                                                    </button>
                                                </Link>
                                            <Link to='' className="editbtn">
                                                    <button className="btn btn-danger" onClick={() => handleDeleteRole(data.id)}>
                                                        <DeleteIcon/>
                                                    </button>
                                                </Link>
                                            </td>
                                        </tr>
                                        ))}
                                    </tbody>
                                    </table>
                                </div>
                                </div>
                            </div>
                        </div>
                    </div>
            </div>
          </section>
        </div>
    </div>
  )
}

export default ReviewList