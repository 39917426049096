import React from 'react'
import LoginTo from './auth/LoginTo'
import BackLinksCss from './backend/layouts/BackLinksCss'
import BackLinksJs from './backend/layouts/BackLinksJs'

const AuthLayout = () => {
 
  return (
    <div>
      <BackLinksCss/>
          <LoginTo/>
    </div>
  )
}

export default AuthLayout
