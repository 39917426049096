import axios from 'axios'

export const Api = () => {
    const http = axios.create({
        // baseURL: 'https://footservertest.ipsitacomputersltd.com/api',
        baseURL: 'https://footbalancetech.com/footserver/api',
        // baseURL: 'http://127.0.0.1:8000/api',
        headers: {
            'content-type': 'application/json'
        }
      });
      return {
        http
      }
}
