import React, { useEffect, useRef, useState } from 'react';
import { Link } from 'react-router-dom';
import $ from 'jquery';
import 'datatables.net';
import 'datatables.net-dt/css/dataTables.dataTables.css';
import swal from 'sweetalert';
import { ProtectAPi } from '../../../../api/ProtectAPi';
import { ServerUrl } from '../../../../api/ServerUrl';
import { SetPermission } from '../../../../auth/SetPermission';
import EditIcon from '../../../tools/EditIcon';
import DeleteIcon from '../../../tools/DeleteIcon';

const ServicesList = () => {
  const { http } = ProtectAPi();
  const httpCPage = ServerUrl;
  const tableRef = useRef(null);
  const [tableData, setTableData] = useState([]);
  const userPermission = SetPermission();
  const [isDataTableInitialized, setIsDataTableInitialized] = useState(false);

  useEffect(() => {
    http.get('/get-services-list-back')
      .then(res => { 
        setTableData(res.data);
      })
      .catch(error => {
        console.error('Error fetching services:', error);
      });
  }, []);

  useEffect(() => {
    if (tableData.length > 0 && !isDataTableInitialized) {
      $(tableRef.current).DataTable();
      setIsDataTableInitialized(true);
    }
  }, [tableData, isDataTableInitialized]);

  const handleDeleteRole = (roleId) => {
    swal({
      title: 'Are you sure?',
      text: 'Once deleted, you will not be able to recover this item!',
      icon: 'warning',
      buttons: true,
      dangerMode: true,
    }).then((willDelete) => {
      if (willDelete) {
        http.delete(`/delete-services/${roleId}`)
           .then(response => {
             if(response.data.status !== 201){
              setTableData(prevRoles => prevRoles.filter(data => data.id !== roleId));
             }
              swal(response.data.msg, {
                icon: response.data.alert,
              });
           })
           .catch(error => {
              swal('Oops! Something went wrong while deleting this item.', {
                icon: 'error',
              });
           });
      }
    });
  };

  return (
    <div>
      <div className="main-content">
        <section className="section">
          <div className="section-body">
            <div className="row">
              <div className="col-12">
                <div className="card">
                  <div className="card-body">
                    <div className="d-flex justify-content-between mb-3">
                      <h5>Services List</h5>
                      <div className="text-end">
                        <Link to='/admin/pages/home/services-add' className="btn btn-primary btn-sm">Add New Services</Link>
                      </div>
                    </div>
                    <div className="table-responsive">
                      <table className="table table-striped" id="table-1" ref={tableRef}>
                        <thead>
                          <tr>
                            <th className="text-center">#</th>
                            <th>Title</th>
                            <th>Image</th>
                            <th>Action</th>
                          </tr>
                        </thead>
                        <tbody>
                          {tableData.map((data, index) => (
                            <tr key={data.id}>
                              <td>{index + 1}</td>
                              <td>{data.title}</td>
                              <td><img alt="" src={httpCPage + '/backend/img/services/' + data.image} width="85" /></td>
                              <td>
                                {userPermission.includes('services_edit') && (
                                  <Link to={`/admin/pages/home/services-edit/${data.id}`} className="editbtn mr-1">
                                    <button className="btn btn-warning">
                                      <EditIcon/>
                                    </button>
                                  </Link>
                                )}
                                {userPermission.includes('services_delete') && (
                                  <button className="btn btn-danger" onClick={() => handleDeleteRole(data.id)}>
                                    <DeleteIcon/>
                                  </button>
                                )}
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </div>
  );
}

export default ServicesList;
